import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DiscardChangesContext = createContext(null);

export const DiscardChangesProvider = ({ children }) => {
  const [allowTabChange, setAllowTabChange] = useState(true);
  const [activeType, setActionType] = useState(null);
  const [navTo, setNavTo] = useState(null);
  const [discardChangesModal, setDiscardChangesModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const beforeUnloadHandler = (event) => {
      if (
        (localStorage.getItem("geofence") === "edit" ||
          !localStorage.getItem("farmLocation")) &&
        window.location.pathname === "/map"
      ) {
        event.preventDefault();
        return (event.returnValue = "");
      }
    };
    const handlePopstate = () => {
      if (
        localStorage.getItem("geofence") === "edit" ||
        !localStorage.getItem("farmLocation")
      ) {
        setNavTo(window.location.pathname);
        navigate("/map");
        setDiscardChangesModal(true);
        setActionType("popstate");
        localStorage.setItem("popstate", "true");
      }
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const handleShowChangesModal = (tab, type) => {
    if (
      tab != 0 &&
      (localStorage.getItem("geofence") === "edit" ||
        !localStorage.getItem("farmLocation"))
    ) {
      setDiscardChangesModal(true);
      setActionType(type);
    }
  };

  const handleCancelMove = () => {
    setDiscardChangesModal(false);
    localStorage.removeItem("popstate");
  };

  const handleConfirmMove = () => {
    if (activeType === "tabChange") {
      localStorage.setItem("currentTab", 1);
    }
    if (activeType === "breadcrumb") {
      navigate("/");
    }
    if (activeType.includes("link")) {
      const link = activeType?.split("-");
      navigate(link[link.length - 1]);
    }
    if (activeType === "popstate") {
      navigate(navTo);
      localStorage.removeItem("popstate");
    }
    setAllowTabChange(true);
    setDiscardChangesModal(false);
    localStorage.setItem("geofence", "done");
    localStorage.setItem("farmLocation", "true");
  };
  return (
    <DiscardChangesContext.Provider
      value={{
        discardChangesModal,
        setDiscardChangesModal,
        allowTabChange,
        setAllowTabChange,
        handleShowChangesModal,
        handleCancelMove,
        handleConfirmMove,
      }}
    >
      {children}
    </DiscardChangesContext.Provider>
  );
};

export const useDiscardChangesContext = () => useContext(DiscardChangesContext);
