import { createContext, useContext, useState } from "react";

const SubadminContext = createContext();

const userInitialState = {
  uID: "",
  name: "",
  email: "",
  mobileNumber: "",
  password: "",
  gateWayID: "",
};

export const SubadminContextProvider = ({ children }) => {
  const [resetSubadminID, setResetSubadminID] = useState();
  const [addSubadmin, setAddSubadmin] = useState(userInitialState);
  const [addSubadminModal, setAddSubadminModal] = useState(false);
  const [reSetSubAdminPasscodeModal, setReSetSubAdminPasscodeModal] =
    useState(false);

  const handleAddSubadminChange = (data) => {
    // console.log(data, "handle subad min log ");
    // const { name, value } = data.target;
    // console.log(name, value, "pp");
    // if (name == "name") {
    //   const regMatch = /^[a-zA-Z]*$/.test(value);

    //   if (!regMatch) return;
    // } else if (name == "mobileNumber") {
    //   const mobMatch = /[^0-9]/g.test(value);
    //   console.log(name, value.length, "pp");
    //   if (value?.length < 10) {
    //     setAddSubadmin((prev) => ({ ...prev, [name]: value }));
    //   }
    //   if (!mobMatch) return;
    // } else {
    //   setAddSubadmin((prev) => ({ ...prev, [name]: value }));
    // }

    const { name, value } = data.target;

    // If the input passes validation, update the state
    setAddSubadmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSubadminModalCancel = () => {
    setAddSubadminModal(false);
    setAddSubadmin(userInitialState);
  };

  const handleAddSubadminModalOpen = () => {
    setAddSubadminModal(true);
  };

  const handleAddNewSubadmin = async () => {};

  const handleResetModalOpen = (id) => {
    setResetSubadminID(id);
    setReSetSubAdminPasscodeModal(true);
    // console.log("uuyywwweuwuwuweuuruyeweuesuwu");
  };

  const handleResetModalCancel = () => {
    setReSetSubAdminPasscodeModal(false);
  };
  return (
    <SubadminContext.Provider
      value={{
        addSubadmin,
        handleAddSubadminChange,
        handleAddNewSubadmin,
        addSubadminModal,
        handleAddSubadminModalCancel,
        handleAddSubadminModalOpen,
        setAddSubadminModal,
        handleResetModalOpen,
        handleResetModalCancel,
        reSetSubAdminPasscodeModal,
        resetSubadminID,
      }}
    >
      {children}
    </SubadminContext.Provider>
  );
};

export const useSubadminContext = () => useContext(SubadminContext);
