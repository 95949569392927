import { Box, Stack } from "@mui/material";
import React from "react";
import {
  ButtonOutlinedRound,
  TypographyWithBg,
  TypographyPrimary,
  ButtonPrimaryRound
} from "../../ComponentsV2/themeComponents";

const ChangesAlert = ({onCancel, onConfirm}) => {
  return (
    <Box>
      <TypographyWithBg
        sx={{ background: "#fc5555", fontSize: 24 }}
        id="modal-modal-title"
        variant="h6"
        component="h2"
      >
        Warning
      </TypographyWithBg>
      <TypographyPrimary sx={{ mb: 3, fontSize: 20, textAlign: "left", ml:2 }}>
       Changes you made may not be saved.
      </TypographyPrimary>
      <Stack>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "right",
            p: 2,
            pt: 0,
          }}
        >
          <ButtonOutlinedRound
            variant="outlined"
            size="large"
            sx={{
              color: "#fc5555",
              borderColor: "#fc5555",
              "&:hover": {
                borderColor: "#fc5555",
              },
            }}
            onClick={onCancel}
          >
            Cancel
          </ButtonOutlinedRound>
          <ButtonPrimaryRound
            variant="contained"
            size="large"
            sx={{
              background: "#fc5555",
              "&:hover": {
                background: "#fc5555",
              },
            }}
            onClick={onConfirm}
          >
            Go on!
          </ButtonPrimaryRound>
        </Box>
      </Stack>
    </Box>
  );
};

export default ChangesAlert;
