import { createContext, useContext, useState } from "react";

const GatewayContext = createContext();

const userInitialState = {
  name: "",
  uID: "",
  gateWayID: "",
  pincode: "",
  city: "",
  state: "",
};

export const GatewayContextProvider = ({ children }) => {
  const [addGateway, setAddGateway] = useState(userInitialState);
  const [addGatewayModal, setAddGatewayModal] = useState(false);

  const handleAddGatewayChange = (data) => {
    let { name, value } = data.target;
    if (name == "pincode") {
      if (value.length <= 6) value = value.replace(/[^0-9]/g, "");
      else return;
    }
    if (name == "gatewayID") value = value.replace(/[^0-9]/g, "");
    if (name == "name") value = value.replace(/[^\w ]/g, "");

    setAddGateway((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddGatewayModalCancel = () => {
    setAddGatewayModal(false);
    setAddGateway(userInitialState);
  };

  const handleAddGatewayModalOpen = () => {
    setAddGatewayModal(true);
  };

  const handleAddNewGateway = async () => {};
  return (
    <GatewayContext.Provider
      value={{
        addGateway,
        handleAddGatewayChange,
        handleAddNewGateway,
        addGatewayModal,
        handleAddGatewayModalCancel,
        handleAddGatewayModalOpen,
        setAddGatewayModal,
      }}
    >
      {children}
    </GatewayContext.Provider>
  );
};

export const useGatewayContext = () => useContext(GatewayContext);
